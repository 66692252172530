import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import '../i18n/i18n'

import { withTranslation } from 'react-i18next'
import styled from 'styled-components'
import SVGSymbols from '~components/homepage/svg/icons/svg'
import CookiesPopup from '~components/homepage/sections/cookies-popup'
import { collection, getDocs } from 'firebase/firestore/lite'

import {
  Button,
  Container,
  ContentContainer,
  DarkButton,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../fonts/css/averta-font.css'

import GlobalStyle from '~components/homepage/sections/global-styles'
import Header from '~components/homepage/sections/header'
import FooterSection from '~components/homepage/sections/footer-section'
import { Helmet } from '~components/helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { findImages, langIndex } from '~components/homepage/utils'
import {
  WorkshopDescription,
  WorkshopsContainer,
  WorkshopTale,
  GalleryTitle,
  Tape,
  ChooseTime,
  WorkshopsContainerTiles,
  WorkshopDescriptionWrapper,
  DescriptionStick,
  DescriptionStickWrapper,
} from '~components/workshop'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'
import { Col } from 'antd'

import { Workshop } from '~src/models/workshop'
import _ from 'lodash'
import { WorkshopGallery } from '~components/workshop/components/Gallery'
import { SplashImage } from '~components/workshop/components/SplashImage'
import OrderModal from '~components/workshop/components/order-modal'
import { workshopIcons } from '~src/models/workshop/map-icons'
import { storeDb } from '~src/api/store-api'
import { WorkshopFaqSection } from '~components/workshop/components/faq'
import { WorkshopDisclaimer } from '~components/workshop/components/Disclaimer'
import { WorkshopCarousel } from '~components/workshop/components/WorkshopCarousel'
import { Parallax } from 'react-parallax'
import { beginCheckout, viewItem, viewItemList } from '~src/analytics'

const StyledContentContainer = styled(ContentContainer)`
  padding-top: 120px;
`

const WorkshopPage = ({ t, i18n }): ReactElement => {
  const switchLang = (lng: string): void => i18n.changeLanguage(lng)
  const [workshops, setWorkshops] = useState<Workshop[]>([])
  const [descriptionHeight, setDescHeight] = useState<number>()
  const [screenWidth, setScreenWidth] = useState<number>()

  const query = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 350) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const fetchWorkshops = async (): void => {
    const store = collection(storeDb, 'workshop')
    const items = await getDocs(store)
    const parsedItems = items.docs.map(doc => doc.data()) as Workshop[]

    viewItemList('workshop', parsedItems)

    setWorkshops(_.sortBy(parsedItems, [o => o.order]))
  }

  useEffect(() => {
    fetchWorkshops()
  }, [])

  const images: object[] = findImages(query, ['kuznia', 'kuznia-tape'])

  const [description, setDescription] = useState<string>()

  const [selectedWorkshops, selectWorkshop] = useState<Workshop[]>([])

  const lngIndex = langIndex(i18n.language)

  const refCallback = el => {
    setDescHeight(el?.getBoundingClientRect?.()?.height)
  }

  const aboveSlider = useMemo(
    () =>
      _.times(
        21,
        index => `Image000${index + 1 < 10 ? `0${index + 1}` : index + 1}`
      ),
    []
  )
  const belowSlider = useMemo(
    () => _.times(20, index => `workshop${index + 1}`),
    []
  )

  const renderDates = (dates: string[]) => {
    const dateItems = dates.filter(d => /\d/.test(d))

    if (!dateItems.length) return null

    return (
      <div className="date-section">
        <span className="bold">{t('workshopPage.dates')}</span>
        {dateItems.map(d => (
          <div key={d} className="date-item knife-params">
            <SVGImage
              svgProps={[KlosySVGId.slideMarker, 'dot-slider', 12, 10]}
            />
            <span>{d}</span>
          </div>
        ))}
      </div>
    )
  }

  useEffect(() => {
    if (description) {
      const w = workshops.find(({ id }) => description === id)
      const index = workshops.findIndex(({ id }) => id === w.id)
      viewItem('workshop', { ...w, images: [] }, index)
    }
  }, [description])

  const processStringHtmlDescription = (html: string) => {
    // Set rules here

    return html.replace('noreferrer', '')
  }

  const renderDesc = (w: Workshop): React.ReactElement => (
    <WorkshopDescriptionWrapper>
      <div ref={refCallback}>
        <WorkshopDescription
          className="desktop-description"
          type="flex"
          justify="center"
          align="stretch"
        >
          <button
            type="submit"
            className="close-button"
            onClick={(e): void => {
              e.stopPropagation()
              e.preventDefault()
              setDescription(undefined)
            }}
          >
            <SVGImage svgProps={[KlosySVGId.cross, 'close', 12, 12]} />
          </button>

          <Col className="description-text" md={{ span: 15, offset: 0 }}>
            <div className="bold">{w.title[lngIndex]}</div>
            <p
              key="paragraph-description"
              dangerouslySetInnerHTML={{
                __html: processStringHtmlDescription(w.description[lngIndex]),
              }}
            />
          </Col>
          <Col md={{ span: 1, offset: 0 }}> </Col>
          <Col className="params" md={{ span: 8, offset: 0 }}>
            <div className="knife-params">
              <div>
                <span>{t('workshopPage.people')}</span>{' '}
                {w.peopleCapacity[lngIndex]}
              </div>
              <div>
                <span>{t('workshopPage.time')}</span> {w.time[lngIndex]}
              </div>
              <div>
                <span>{t('workshopPage.price')}</span> {w.price[lngIndex]}{' '}
                {t('currency')}
              </div>
            </div>

            <div>
              <Button
                className="order-button"
                onClick={(): void => {
                  beginCheckout(
                    'workshop',
                    w,
                    i18n.language === 'EN' ? 'EUR' : 'PLN',
                    'klosy.pl/workshop'
                  )

                  selectWorkshop([w])
                }}
              >
                {t('workshopPage.order')}{' '}
              </Button>
            </div>
            {renderDates(w.date)}
          </Col>
        </WorkshopDescription>
      </div>
    </WorkshopDescriptionWrapper>
  )

  const orderModal = (
    <OrderModal
      active={!!selectedWorkshops.length}
      onCloseModal={(): void => {
        selectWorkshop([])
      }}
      workshops={selectedWorkshops}
    />
  )

  return (
    <SectionWrapper bgColor="#F7F5F1">
      <Helmet />
      <Container>
        <GlobalStyle />
        <LanguageContext.Provider value={{ t, lang: i18n.language }}>
          <CookiesPopup />
          <Header onSwitchLang={switchLang} />
          <SVGSymbols />
          <SplashImage />

          <StyledContentContainer>
            <WorkshopsContainerTiles>
              {workshops.map(
                (w): React.ReactElement => {
                  const isActive = description === w.id
                  return (
                    <>
                      <WorkshopTale
                        onClick={(): void => setDescription(w.id)}
                        style={{
                          marginBottom: isActive
                            ? descriptionHeight - 50
                            : undefined,
                        }}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${w.image})`,
                          }}
                          className="image"
                        />
                        <div className="icon">
                          {workshopIcons[w.icon]}
                          <DescriptionStickWrapper>
                            {!_.isNil(description) && isActive && (
                              <DescriptionStick />
                            )}
                          </DescriptionStickWrapper>

                          {!_.isNil(description) &&
                            isActive &&
                            renderDesc(
                              workshops.find(({ id }) => description === id)
                            )}
                        </div>

                        <div className="title">{w.title[lngIndex]}</div>
                        <div className="date">{w.time[lngIndex]}</div>

                        <div className="price">
                          <hr className="price-hr" />
                          {w.price[lngIndex]} {t('currency')}
                        </div>
                      </WorkshopTale>
                    </>
                  )
                }
              )}
            </WorkshopsContainerTiles>
          </StyledContentContainer>

          <WorkshopDisclaimer />

          <ChooseTime>
            <p>Jesteś zainteresowany warsztatami?</p>

            <DarkButton onClick={(): void => selectWorkshop(workshops)}>
              {t('workshopPage.order')}{' '}
            </DarkButton>
          </ChooseTime>

          <Tape>
            <Parallax
              bgImage={images[1]?.node.childImageSharp.fluid.src}
              strength={screenWidth <= 1024 ? 0 : 300}
            >
              <div style={{ height: '350px' }} />
            </Parallax>
          </Tape>

          <ContentContainer>
            <WorkshopsContainer>
              <GalleryTitle>galeria warsztatów</GalleryTitle>
              <WorkshopCarousel images={aboveSlider} />
              <WorkshopGallery />
              <WorkshopFaqSection />
              <GalleryTitle>PRACE WARSZTATOWICZÓW</GalleryTitle>
              <WorkshopCarousel images={belowSlider} />
            </WorkshopsContainer>
          </ContentContainer>
          {orderModal}
          <FooterSection />
        </LanguageContext.Provider>
      </Container>
    </SectionWrapper>
  )
}

export default withTranslation('translation')(WorkshopPage)
