import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { mediaQueries, palette } from '~components/homepage/styled/constants'
import { MaterialDescription } from '~components/homepage/styled/grid-materials'
import { ArrowSvg } from '~components/homepage/svg/icons/arrow'

export const SplashImageWrapper = styled.div`
  margin-top: 125px;
  position: relative;

  @media (max-width: ${mediaQueries.small}) {
    & {
      margin-top: 160px;
    }
  }
`

export const SplashImageText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  flex-direction: column;

  .textWrapper {
    max-width: 490px;
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  .name,
  .title,
  .text {
    color: ${palette.smokeWhite};
  }

  .name {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.5px;
  }

  .title {
    font-size: 25px;
    line-height: 30px;
  }

  .text {
    font-size: 13px;
    line-height: 22px;
  }
`

export const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background: ${palette.darkBlue};
  opacity: 0.4;
`

export const WorkshopsContainer = styled.div`
  gap: 20px;
  text-align: center;
  position: relative;
  overflow: unset;
  width: 100%;
  margin-bottom: 50px;
`

export const WorkshopsContainerTiles = styled.div`
  gap: 20px;
  text-align: center;
  position: relative;
  overflow: unset;
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
`

export const WorkshopTale = styled.div`
  max-width: 49%;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-bottom: 60px;

  @media (max-width: ${mediaQueries.small}) {
    & {
      max-width: 80%;
    }
  }

  .icon {
    height: 69px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 0.5px solid ${palette.inputBorder};

    svg path {
      fill: white;
    }
  }

  .price-hr {
    width: 20px;
    background: ${palette.inputBorder};
    border: none;
    height: 1px;
    position: absolute;
    left: 50%;
    top: -90%;
    transform: translateX(-50%);
  }

  .title,
  .date,
  .price {
    color: ${palette.darkBlue};
    font-size: 11px;
    margin-top: 20px;
    position: relative;
  }

  .price {
    font-size: 14px;
    font-family: 'Averta-Bold', sans-serif;
  }

  .title {
    font-family: 'Averta-Bold', sans-serif;
  }

  .image {
    height: 350px;
    width: 100%;
    background-size: cover;

    @media (max-width: ${mediaQueries.small}) {
      & {
        background-position: center;
      }
    }
  }
`

export const WorkshopDescriptionWrapper = styled('div')`
  position: absolute;
  width: 100%;
  left: 0;
  transform: translateY(100%);
  height: 1px;
  z-index: 11;
  margin-top: 68px;
`

export const WorkshopDescription = styled(MaterialDescription)`
  opacity: 1;
  width: 100%;
  text-align: left;
  line-height: 22px;

  b,
  strong,
  .bold {
    font-family: 'Averta-Semibold', sans-serif;
    color: ${palette.darkBlue};
    -webkit-font-smoothing: antialiased;
  }

  .params {
    padding-top: 0px;
  }

  .knife-params {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .date-section {
    margin-top: 24px;
    font-size: 11px;

    .date-item {
      display: flex;
      gap: 5px;
      margin-top: 3px;
      align-items: center;
      flex-direction: row;
      justify-content: unset;

      span {
        display: flex;
        align-items: center;
      }

      svg {
        opacity: 0.7;
        margin-top: -2px;
      }
    }
  }
`

const StyledNextArrow = styled.span`
  width: 11px;
  right: -35px !important;
  transform: rotate(180deg);
  transform-origin: 50% 50%;
  z-index: 100;
  top: 50% !important;

  transition: 0.3s;

  svg {
    width: 11px;
  }

  &::before {
    display: none;
  }
`

const StyledPrevArrow = styled.span`
  left: -35px !important;
  width: 11px;
  z-index: 100;
  transform: none !important;
  transition: 0.3s;

  svg {
    width: 11px;
  }

  &::before {
    display: none;
  }
`

/*eslint-disable */
export function NextArrow({ className, onClick }: any): ReactElement {
  return (
    <StyledNextArrow onClick={onClick} className={className}>
      <ArrowSvg />
    </StyledNextArrow>
  )
}

export function PrevArrow({ className, style, onClick }: any): ReactElement {
  return (
    <StyledPrevArrow
      onClick={onClick}
      className={className}
      style={{ ...style }}
    >
      <ArrowSvg />
    </StyledPrevArrow>
  )
}

export const GalleryTitle = styled.div`
  font-size: 10px;
  color: ${palette.darkBlue};
  text-transform: uppercase;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Averta-Semibold', sans-serif;
  letter-spacing: 2px;
`

export const ChooseTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  flex-direction: column;
  background: #fff;
  font-size: 15px;
  gap: 2px;
  color: ${palette.darkBlue};
`

export const Tape = styled.div`
  height: 350px;
  width: 100%;

  @media (max-width: ${mediaQueries.small}) {
    & > div {
      background-position: center;
    }
  }
`

export const DescriptionStick = styled.div`
  height: 70px;
  width: 1px;
  background: ${palette.borderColor};
  position: absolute;
  right: 40px;
  transform: translateY(-50%);
`

export const DescriptionStickWrapper = styled.div`
  height: 1px;
  width: 100%;
  position: relative;
`
