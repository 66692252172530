import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import LanguageContext from '~components/homepage/context/language-context'
import { mediaQueries, palette } from '~components/homepage/styled/constants'
import { renderPanels } from '~components/homepage/styled/shared'

const FaqContainer = styled.div`
  max-width: 555px;
  margin: 0 auto;
  color: ${palette.darkBlue};
  margin-top: 80px;
  text-align: left;

  .accordion-title {
    font-size: 14px;
  }

  .collapse-wrapper {
    .accordion-title {
      margin-bottom: 18px;
    }
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      width: 80%;
    }
  }
`

export const WorkshopFaqSection = () => {
  const [activePanel, setActivePanel] = useState('')
  const { t } = useContext(LanguageContext)

  return (
    <FaqContainer>
      {renderPanels(
        t('workshop.faq.info', { returnObjects: true }),
        activePanel,
        setActivePanel
      )}
    </FaqContainer>
  )
}
