import { useStaticQuery, graphql } from 'gatsby'
import React, { useContext } from 'react'
import { Parallax } from 'react-parallax'
import LanguageContext from '~components/homepage/context/language-context'
import { findImages } from '~components/homepage/utils'
import { SplashImageWrapper, Backdrop, SplashImageText } from './styled'

export const SplashImage = () => {
  const query = useStaticQuery(
    graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxHeight: 350) {
                  originalName
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )
  const { t } = useContext(LanguageContext)
  const [image]: object[] = findImages(query, ['kuznia'])

  return (
    <SplashImageWrapper>
      <Parallax
        blur={0}
        bgImage={image?.node.childImageSharp.fluid.src}
        bgImageAlt="the cat"
        strength={200}
      >
        <div style={{ height: '350px' }} />
      </Parallax>
      <Backdrop />
      <SplashImageText>
        <div className="textWrapper">
          <p className="name">{t('workshopPage.sectionName')}</p>
          <p className="title">{t('workshopPage.title')}</p>
          <p className="text">{t('workshopPage.splashText')}</p>
        </div>
      </SplashImageText>
    </SplashImageWrapper>
  )
}
