import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { mediaQueries, palette } from '~components/homepage/styled/constants'
import { Link } from '~components/homepage/styled/header'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

const StyledDisclamer = styled.div`
  text-align: center;
  font-size: 11px;
  max-width: 555px;
  margin: 0 auto;
  margin-bottom: 100px;
  color: ${palette.darkBiege};

  svg {
    opacity: 0.5;
  }

  @media (max-width: ${mediaQueries.small}) {
    & {
      width: 80%;
    }
  }
`

export const WorkshopDisclaimer = () => {
  return (
    <StyledDisclamer>
      <SVGImage svgProps={[KlosySVGId.slideMarker, 'dot-disclaimer', 14, 12]} />
      <br />
      <br />
      <Trans i18nKey="workshopPage.disclamer">
        {[
          <br />,
          <Link target="_blank" href="/privacy-policy-workshop"></Link>,
        ]}
      </Trans>
    </StyledDisclamer>
  )
}
